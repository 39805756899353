export class CreateComputingPlatformHierarchyAttachment {
  static readonly type = '[ComputingPlatformHierarchy] Create attachment';

  constructor(
    public computingPlatformHierarchyId: number,
    public file: File,
    public description: string
  ) {}
}

export class RemoveComputingPlatformHierarchyAttachment {
  static readonly type = '[ComputingPlatformHierarchy] Remove attachment';

  constructor(
    public computingPlatformHierarchyId: number,
    public attachmentId: number
  ) {}
}

export class UpdateComputingPlatformHierarchyAttachment {
  static readonly type = '[ComputingPlatformHierarchy] Update attachment';

  constructor(
    public computingPlatformHierarchyId: number,
    public attachmentId: number,
    public description: string
  ) {}
}

export class DownloadComputingPlatformHierarchyAttachment {
  static readonly type = '[ComputingPlatformHierarchy] Download attachment';

  constructor(
    public computingPlatformHierarchyId: number,
    public attachmentId: number
  ) {}
}

export class ListComputingPlatformHierarchyAttachments {
  static readonly type = '[ComputingPlatformHierarchy] List attachments';

  constructor(public computingPlatformHierarchyId: number) {}
}
