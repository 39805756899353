import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { AttachmentListItem } from './attachment-table/attachment-table.component';
import { ComputingPlatformHierarchyAttachmentClient } from '../clients/apiClients';
import { tap } from 'rxjs';
import {
  CreateComputingPlatformHierarchyAttachment,
  DownloadComputingPlatformHierarchyAttachment,
  ListComputingPlatformHierarchyAttachments,
  RemoveComputingPlatformHierarchyAttachment,
  UpdateComputingPlatformHierarchyAttachment
} from './computing-platform-hierarchy-attachments.actions';

export class ComputingPlatformHierarchyAttachmentStateModel {
  attachments: AttachmentListItem[] | null = null;
  attachmentsLoading = false;
}

@State<ComputingPlatformHierarchyAttachmentStateModel>({
  name: 'computingPlatformHierarchyAttachment',
  defaults: {
    attachments: null,
    attachmentsLoading: false
  }
})
@Injectable()
export class ComputingPlatformHierarchyAttachmentState {
  computingPlatformHierarchyAttachmentClient = inject(
    ComputingPlatformHierarchyAttachmentClient
  );

  @Action(CreateComputingPlatformHierarchyAttachment)
  createComputingPlatformHierarchyAttachment(
    ctx: StateContext<ComputingPlatformHierarchyAttachmentStateModel>,
    action: CreateComputingPlatformHierarchyAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.computingPlatformHierarchyAttachmentClient
      .createComputingPlatformHierarchyAttachment(
        action.computingPlatformHierarchyId,
        action.description,
        this.mapFile(action.file)
      )
      .pipe(
        tap((createdAttachment) => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          ctx.patchState({
            attachments: [...attachments, createdAttachment],
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(ListComputingPlatformHierarchyAttachments)
  listAttachments(
    ctx: StateContext<ComputingPlatformHierarchyAttachmentStateModel>,
    action: ListComputingPlatformHierarchyAttachments
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.computingPlatformHierarchyAttachmentClient
      .listComputingPlatformHierarchyAttachments(
        action.computingPlatformHierarchyId
      )
      .pipe(
        tap((attachments) => {
          ctx.patchState({
            attachments: attachments,
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(RemoveComputingPlatformHierarchyAttachment)
  removeComputingPlatformHierarchyAttachment(
    ctx: StateContext<ComputingPlatformHierarchyAttachmentStateModel>,
    action: RemoveComputingPlatformHierarchyAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.computingPlatformHierarchyAttachmentClient
      .deleteComputingPlatformHierarchyAttachment(
        action.computingPlatformHierarchyId,
        action.attachmentId
      )
      .pipe(
        tap(() => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          const updatedAttachments = attachments.filter(
            (a: AttachmentListItem) => a.id !== action.attachmentId
          );
          ctx.patchState({
            attachments: updatedAttachments,
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(UpdateComputingPlatformHierarchyAttachment)
  updateComputingPlatformHierarchyAttachment(
    ctx: StateContext<ComputingPlatformHierarchyAttachmentStateModel>,
    action: UpdateComputingPlatformHierarchyAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.computingPlatformHierarchyAttachmentClient
      .updateComputingPlatformHierarchyAttachment(
        action.computingPlatformHierarchyId,
        action.attachmentId,
        action.description
      )
      .pipe(
        tap(() => {
          const attachments = ctx.getState().attachments;
          if (!attachments) {
            ctx.patchState({
              attachmentsLoading: false
            });
            return;
          }
          const updatedAttachments = attachments.map((a: AttachmentListItem) =>
            a.id === action.attachmentId
              ? { ...a, description: action.description }
              : a
          );
          ctx.patchState({
            attachments: updatedAttachments,
            attachmentsLoading: false
          });
        })
      );
  }

  @Action(DownloadComputingPlatformHierarchyAttachment)
  downloadComputingPlatformHierarchyAttachment(
    ctx: StateContext<ComputingPlatformHierarchyAttachmentStateModel>,
    action: DownloadComputingPlatformHierarchyAttachment
  ) {
    ctx.patchState({ attachmentsLoading: true });
    return this.computingPlatformHierarchyAttachmentClient
      .downloadComputingPlatformHierarchyAttachment(
        action.computingPlatformHierarchyId,
        action.attachmentId
      )
      .pipe(
        tap((result) => {
          const aElement = document.createElement('a');
          aElement.setAttribute('download', result.fileName ?? 'file');
          const href = URL.createObjectURL(result.data);
          aElement.href = href;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(href);
          ctx.patchState({
            attachmentsLoading: false
          });
        })
      );
  }

  @Selector()
  static attachments(state: ComputingPlatformHierarchyAttachmentStateModel) {
    return state.attachments;
  }

  @Selector()
  static attachmentsLoading(
    state: ComputingPlatformHierarchyAttachmentStateModel
  ) {
    return state.attachmentsLoading;
  }

  private mapFile(file: File) {
    return { data: file, fileName: file.name };
  }
}
